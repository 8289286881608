import React from 'react';
import '../../assets/css/m~house.css';
import Layout from '../../components/Layout';

const House = () => (
  <div className="house">
    {leftRoof()}
    {rightRoof()}
    {leftWall()}
    {door()}
    {rightWall()}
    {floor()}
  </div>
);

const leftRoof = () => <p className="house-roof-left">we start the house with the roof</p>;

const rightRoof = () => <p className="house-roof-right">because this isn't sparta, this is js</p>;

const leftWall = () => <p className="house-left-wall">concretely concrete specifically</p>;

const rightWall = () => <p className="house-right-wall">relatively real almost concrete</p>;

const floor = () => <p className="house-floor">roots fundamentally root</p>;

const door = () => <p className="house-door">door</p>;

const HouseLayout = ({ location }) => 
  <Layout location={location}>
    <House />
  </Layout>;

export default HouseLayout;